import * as Yup from "yup";

const FirstLoginValidation = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  course: Yup.string().required("Course name is required"),
  institutionType: Yup.string().required("Institution type is required"),
  institutionName: Yup.string().required("Institution name is required"),
//   degreeName: Yup.string().when("institutionType", {
    // is: (val: string) => val === "College/University",
    // then: Yup.string().required("Degree name is required"),
    // otherwise: Yup.string().notRequired(),
//   }),
//   semester: Yup.string().when("institutionType", {
    // is: (val: string) => val === "College/University",
    // then: Yup.string().required("Semester is required"),
    // otherwise: Yup.string().notRequired(),
//   }),
//   graduationYear: Yup.string().when("institutionType", {
    // is: (val: string) => val === "College/University",
    // then: Yup.string().required("Year of graduation is required"),
    // otherwise: Yup.string().notRequired(),
//   }),
//   class: Yup.string().when("institutionType", {
    // is: (val: string) => val === "School",
    // then: Yup.string().required("Class is required"),
    // otherwise: Yup.string().notRequired(),
//   }),
//   passingYear: Yup.string().when("institutionType", {
    // is: (val: string) => val === "School",
    // then: Yup.string().required("Year of passing is required"),
    // otherwise: Yup.string().notRequired(),
//   }),
  codingExperience: Yup.string().required("Coding experience is required"),
  trainingType: Yup.string().required("Training type is required"),
  batch: Yup.string().required("Batch is required"),
  terms: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

export default FirstLoginValidation;