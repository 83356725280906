import React, { useEffect, useState } from "react";
import { usePost } from "./../../Services/API/APIHandlers";
import { useFormik } from "formik";
import { ResetPasswordDefault } from "./../../Services/Helper/AuthDefaults";
import { ResetPasswordType } from "./../../Services/Types/AuthTypes";
import { ResetPasswordValidation } from "./../../Services/Validations/AuthValidation";
import { useLocation } from "react-router-dom";

function ResetPassword() {
  const [userId, setUserId] = useState<string>("");
  const [confirmationToken, setConfirmationToken] = useState<string>("");

  const { search } = useLocation();

  const {
    mutate: resetPassword,
    isLoading,
    isError,
    isSuccess,
  } = usePost(`reset-password/${userId}/${confirmationToken}/`);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const userIdParam = params.get("user_id");
    const tokenParam = params.get("token");

    if (userIdParam && tokenParam) {
      setUserId(userIdParam);
      setConfirmationToken(tokenParam);
    }
  }, [search]);

  const formik = useFormik({
    initialValues: ResetPasswordDefault,
    validationSchema: ResetPasswordValidation,
    onSubmit: (values: ResetPasswordType) => {
      let payload = {
        password: values.password,
        password2: values.confirm_password,
      };

      resetPassword(payload);
      console.log(payload);
    },
  });

  useEffect(() => {
    if (userId) console.log(userId, confirmationToken);

    return () => {};
  }, [userId, confirmationToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div
        className="col-md-4 p-5 rounded shadow"
      >
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-5 pb-5 fw-bold">Reset Password</h1>
          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="password"
            >
              Enter New Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.password && formik.errors.password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.password}
              </span>
            ) : null}
            <input
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              type="password"
              id="password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.confirm_password}
              </span>
            ) : null}
            <input
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              name="confirm_password"
              id="confirm_password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="d-flex flex-row pt-5 justify-content-center">
            <button type="submit" className="btn btn-dark mb-4 px-5 rounded-3">
              <b>{!isLoading ? "RESET" : "Loading..."}</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
