function NotFound() {
  return (
    <div className="container">
      <div className="justify-content-center text-center py-5">
        <h1 className="fw-bold text-secondary display-4 pt-5">
          Oops...! Nothing here
        </h1>
      </div>
    </div>
  );
}

export default NotFound;
