import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useGetValidate } from "../../../Services/API/APIHandlers";
import { FirstUserLoginInterface } from "../../../Services/Interfaces/CreateUserInterface";

const Profile = () => {
  // Dummy data for profile values

  const [profileData, setProfileData] =
    useState<FirstUserLoginInterface | null>(null);

  const navigate = useNavigate();

  const {
    mutate: getProfile,
    isLoading,
    error: registerNewError,
    data: registerNewData,
    isSuccess,
  } = useGetValidate("/get-profile");

  useEffect(() => {
    if (isSuccess) {
      // Call a toast
      toast.success("Thank you for the details");
      setProfileData(registerNewData.data);
      localStorage.setItem("cd", registerNewData.data._id);
      localStorage.setItem("phone", registerNewData.data.phone);
    }
  }, [isSuccess, registerNewData]);

  useEffect(() => {
    if (registerNewError instanceof AxiosError) {
      // Call a toast
      toast.error(registerNewError?.response?.data.message);
    }
  }, [registerNewError]);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Box
      width={"100%"}
      className="padding-top-mob-lg"
      p={8}
      bg="gray.100"
      borderRadius="md"
      boxShadow="md"
    >
      <Container className="pb-5 padding-top-mob">
        <Row alignItems="center" justify="space-between" mb={8}>
          {profileData ? (
            <Flex className="col-md-6" alignItems="center">
              <Avatar name={profileData.name} size="xl" />
              <Box ml={4}>
                <Heading size="lg">{profileData.name}</Heading>
                <Text>{profileData.email}</Text>
              </Box>
            </Flex>
          ) : (
            <Spinner />
          )}
          {/* <div className="col-md-6 d-flex flex-row justify-content-end align-items-center">
            <Button colorScheme="teal">Edit Profile</Button>
          </div> */}
        </Row>
      </Container>
      {profileData && (
        <Box>
          <Heading size="lg" mb={4} borderBottom="1px solid" pb={2}>
            Profile Information
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <ProfileCard title="My Courses">
              <Text>
                <strong>Full Stack Web Development</strong>
              </Text>
              <Text>
                <strong>Registration Status:</strong>{" "}
                <span className="badge bg-success">Complete</span>
              </Text>
              <Text>
                <strong>Enrollement:</strong>{" "}
                <span
                  className={
                    "badge " +
                    (profileData?.status ? "bg-success" : "bg-warning")
                  }
                >
                  {profileData?.status ? "Complete" : "Pending"}
                </span>
              </Text>
              {profileData?.isEMI && (
                <Text>
                  <strong>EMI Left:</strong>{" "}
                  <span className="badge bg-success">
                    {3 - profileData.emiNo}
                  </span>
                </Text>
              )}

              {!profileData?.status && (
                <Button
                  onClick={() => navigate("/payment-details")}
                  colorScheme="green"
                >
                  Complete Process Now
                </Button>
              )}
            </ProfileCard>
            <ProfileCard title="Personal Information">
              <Text>
                <strong>Name:</strong> {profileData.name}
              </Text>
              <Text>
                <strong>Email:</strong> {profileData.email}
              </Text>
              <Text>
                <strong>Phone:</strong> {profileData.phone}
              </Text>
            </ProfileCard>
            <ProfileCard title="Education Information">
              <Text>
                <strong>Course Name:</strong> {profileData.course}
              </Text>
              <Text>
                <strong>Institution Type:</strong> {profileData.institutionType}
              </Text>
              <Text>
                <strong>Institution Name:</strong> {profileData.institutionName}
              </Text>
              {profileData.institutionType === "College/University" && (
                <>
                  <Text>
                    <strong>Degree Name:</strong> {profileData.degreeName}
                  </Text>
                  <Text>
                    <strong>Semester:</strong> {profileData.semester}
                  </Text>
                  <Text>
                    <strong>Graduation Year:</strong>{" "}
                    {profileData.graduationYear}
                  </Text>
                </>
              )}
              {profileData.institutionType === "School" && (
                <>
                  <Text>
                    <strong>Class:</strong> {profileData.class}
                  </Text>
                  <Text>
                    <strong>Passing Year:</strong> {profileData.passingYear}
                  </Text>
                </>
              )}
            </ProfileCard>
            <ProfileCard title="Skills & Experience">
              <Text>
                <strong>Coding Experience:</strong>{" "}
                {profileData.codingExperience}
              </Text>
              <Text>
                <strong>Training Type:</strong> {profileData.trainingType}
              </Text>
              <Text>
                <strong>Languages Known:</strong> {profileData.languagesKnown}
              </Text>
              <Text>
                <strong>Batch:</strong> {profileData.batch}
              </Text>
            </ProfileCard>
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

type ProfileCardType = { title: string; children: any };

const ProfileCard = (props: ProfileCardType) => {
  return (
    <Box p={10} bg="white" borderRadius="md" boxShadow="md">
      <Heading size="md" mb={8}>
        {props.title}
      </Heading>
      {props.children}
    </Box>
  );
};
export default Profile;
