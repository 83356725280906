import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthenticated } from "../Atoms/AuthAtoms";
import { isTokenValid } from "../API/APIHandlers";

type PlainRouteProps = {
  children: React.ReactNode | string;
};

const PlainRoute = (props: PlainRouteProps) => {
  const navigate = useNavigate();
  const [tokenValid, setIsTokenValid] = useState<boolean>(false);
  const isAuth = useRecoilValue(isAuthenticated);

  // const userTokenValue = localStorage.getItem("token");

  useEffect(() => {
    let isValid = isTokenValid();
    setIsTokenValid(false);
    console.log(isAuth, isValid);
    if (isAuth || isValid) {
      setIsTokenValid(true);
      navigate("/register-new");
    }
  }, [isAuth]);

  return (
    <React.Fragment> {!tokenValid ? props.children : null}</React.Fragment>
  );
};

export default PlainRoute;
