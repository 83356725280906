import { useFormik } from "formik";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { usePost } from "./../../Services/API/APIHandlers";
import { ChangePasswordDefault } from "./../../Services/Helper/AuthDefaults";
import { ChangePasswordType } from "./../../Services/Types/AuthTypes";
import { ChangePasswordValidation } from "./../../Services/Validations/AuthValidation";

function ChangePassword() {
  const {
    mutate: changePassword,
    isLoading,
    isError,
    isSuccess,
    data,
  } = usePost("/change-password");

  const formik = useFormik({
    initialValues: ChangePasswordDefault,
    validationSchema: ChangePasswordValidation,
    onSubmit: (values: ChangePasswordType) => {
      const data: ChangePasswordType = JSON.parse(JSON.stringify(values));
      console.log(values);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-4 p-5 rounded shadow">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-6 pb-5 fw-bold">Change Password</h1>
          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="old_password"
            >
              Enter Old Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.old_password && formik.errors.old_password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.old_password}
              </span>
            ) : null}
            <input
              value={formik.values.old_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="old_password"
              type="password"
              id="old_password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="new_password"
            >
              Enter New Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.new_password && formik.errors.new_password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.new_password}
              </span>
            ) : null}
            <input
              value={formik.values.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="new_password"
              type="password"
              id="new_password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.confirm_password}
              </span>
            ) : null}
            <input
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="confirm_password"
              type="password"
              id="confirm_password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="d-flex flex-row justify-content-center pt-4">
            <button
              type="submit"
              className="btn btn-block btn-dark mb-4 px-5 rounded-3"
            >
              <b>{!isLoading ? "CONFIRM" : "Loading..."}</b>
            </button>
          </div>
        </form>
      </div>
      <div className="text-center pt-2">
        <span className="fw-bold">
          Want to try again? <Link to="/login">Login</Link>
        </span>
      </div>
    </div>
  );
}

export default ChangePassword;
