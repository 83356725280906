import { RecoilState, atom } from 'recoil';
import { LocalTokenSetType } from "../Types/AuthTypes";

export const refreshToken: RecoilState<string> = atom<string>({
    key: "REFRESH_TOKEN",
    default: ''
});

export const accessToken: RecoilState<string> = atom<string>({
    key: "ACCESS_TOKEN",
    default: ''
});

export const currentUser: RecoilState<string> = atom<string>({
    key: 'CURRENT_USER',
    default: ''
})

export const localTokenSet: RecoilState<LocalTokenSetType | null> = atom<LocalTokenSetType | null>({
    key: 'LOCAL_TOKEN_STRING',
    default: null
});

export const isAuthenticated: RecoilState<boolean> = atom<boolean>({
    key: 'IS_AUTHENTICATED',
    default: false
});
