import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { usePost } from "./../../Services/API/APIHandlers";
import { PhoneVerificationDefault } from "./../../Services/Helper/AuthDefaults";
import { PhoneVerificationType } from "./../../Services/Types/AuthTypes";
import { PhoneVerificationValidation } from "./../../Services/Validations/AuthValidation";

function PhoneVerification() {
  const {
    mutate: verifyPhone,
    isLoading,
    isError,
    isSuccess,
  } = usePost("/phone-verify");
  const [phone, setPhone] = useState<string>("");

  const formik = useFormik({
    initialValues: PhoneVerificationDefault,
    validationSchema: PhoneVerificationValidation,
    onSubmit: (values: PhoneVerificationType) => {
      const data: PhoneVerificationType = JSON.parse(JSON.stringify(values));
      console.log(values);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-4 p-5 rounded shadow">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-4 fw-bold">Phone Verification</h1>
          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="phone_number"
            >
              Phone Number
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.phone_number}
              </span>
            ) : null}
            <input
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="tel"
              maxLength={10}
              id="phone_number"
              name="phone_number"
              className="form-control"
              placeholder="98765XXXXX"
            />
          </div>

          <div className="d-flex pt-3 flex-row justify-content-center">
            <button
              type="submit"
              className="btn btn-block btn-dark mb-4 px-5 rounded-3"
            >
              <b>{!isLoading ? "SEND OTP" : "Loading..."}</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PhoneVerification;
