import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiPhoneCall } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useGetValidate } from "../../../Services/API/APIHandlers";
import { FirstUserLoginInterface } from "../../../Services/Interfaces/CreateUserInterface";

type PropTypes = {
  title: string;
  amount: string;
  buttonText: string;
  path: string;
  enquire?: boolean;
};

const PaymentCard = ({
  title,
  amount,
  buttonText,
  path,
  enquire,
}: PropTypes) => {
  const navigate = useNavigate();

  return (
    <Box
      bgColor={"white"}
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      p={6}
      m={4}
    >
      <Heading as="h3" size="lg" mb={4}>
        {title}
      </Heading>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        &#8377; {amount}
      </Text>
      <Text fontSize="md" mb={6}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
        auctor faucibus libero, id fermentum neque porta vitae.
      </Text>
      {!enquire ? (
        <Button onClick={() => navigate(path)} colorScheme="green">
          {buttonText}
        </Button>
      ) : (
        <>
          <a
            className="btn btn-success fw-bold d-flex align-items-center"
            style={{ width: "fit-content" }}
            href="tel:+916372753095"
          >
            <FiPhoneCall /> &nbsp; Call Us
          </a>
        </>
      )}
    </Box>
  );
};

function checkEmailInArray(email: string, emailArray: Array<string>) {
  for (let i = 0; i < emailArray.length; i++) {
    if (emailArray[i] === email) {
      return true;
    }
  }
  return false;
}

const emailArray = [
  "nikitamondal2005@gmail.com",
  // "nikhilshrivastava1025@gmail.com",
  "rakeshkumardhn3@gmail.com",
];

const PaymentDetails = () => {
  const [data, setData] = useState<FirstUserLoginInterface | null>(null);
  const {
    mutate: getProfile,
    isLoading,
    error: registerNewError,
    data: registerNewData,
    isSuccess,
  } = useGetValidate("/get-profile");

  useEffect(() => {
    if (isSuccess) {
      setData(registerNewData.data);
    }
  }, [isSuccess, registerNewData]);

  useEffect(() => {
    if (registerNewError instanceof AxiosError) {
      toast.error(registerNewError?.response?.data.message);
    }
  }, [registerNewError]);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Flex direction="column" align="center">
        {/* <Heading textAlign={"center"} as="h1" size="xl" mb={8}>
          
        </Heading> */}

        <Heading as="h1" textAlign={"center"} size="xl" my={8}>
          {!data?.status
            ? "Choose your payment plan"
            : "Your Full Stack Web Development package is landing Soon..."}
        </Heading>
        <Container>
          {!data?.status && (
            <Row className="justify-content-center">
              {!data?.isEMI ? (
                <>
                  <PaymentCard
                    title="Full Payment"
                    amount="4999.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                  {/* <PaymentCard
                    title="Breakup EMI"
                    amount="--"
                    buttonText="Enquire Now"
                    path="/payment"
                    enquire={true}
                  /> */}
                </>
              ) : checkEmailInArray(data.email, emailArray) ? (
                <>
                  <PaymentCard
                    title="EMI I"
                    amount="1500.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                  <PaymentCard
                    title="EMI II"
                    amount="1500.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                  <PaymentCard
                    title="EMI II"
                    amount="2000.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                </>
              ) : (
                <>
                  <PaymentCard
                    title="EMI I"
                    amount="2500.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                  <PaymentCard
                    title="EMI II"
                    amount="2500.00"
                    buttonText="Pay Now"
                    path="/payment"
                  />
                </>
              )}
            </Row>
          )}
        </Container>
      </Flex>
    </Flex>
  );
};

export default PaymentDetails;
