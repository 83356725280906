import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { usePost } from "../../Services/API/APIHandlers";
import { isAuthenticated, localTokenSet } from "../../Services/Atoms/AuthAtoms";
import { LoginDefault } from "../../Services/Helper/AuthDefaults";
import { LocalTokenSetType, LoginType } from "../../Services/Types/AuthTypes";
import { LoginValidation } from "../../Services/Validations/AuthValidation";
import { LogoIcon } from "./Logo";

export const SignInForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const [localTokenData, setLocalTokenData] = useRecoilState(localTokenSet);

  const [loginErr, setLoginErr] = useState<AxiosError | null>();

  const {
    mutate: login,
    isLoading,
    error: loginError,
    data: loginData,
    isSuccess,
  } = usePost("/login");

  const formik = useFormik({
    initialValues: LoginDefault,
    validationSchema: LoginValidation,
    onSubmit: (values: LoginType) => {
      let payload = {
        password: values.password,
        email: values.email,
      };

      login(payload);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      let tokenData: LocalTokenSetType = {
        accessToken: loginData.data.accessToken,
        refreshToken: loginData.data.refreshToken,
      };

      // Call a toast
      toast.success("Logged In");
      setLocalTokenData(tokenData);
      setRecoil(isAuthenticated, true);
      localStorage.setItem("token", JSON.stringify(tokenData));
      localStorage.setItem("email", formik.values.email);
      localStorage.setItem("v", JSON.stringify({ verified: loginData.data.v }));
      navigate("/register-new");
    }
  }, [isSuccess, loginData, setLocalTokenData]);

  useEffect(() => {
    if (loginError instanceof AxiosError) {
      // Call a toast
      toast.error(loginError?.response?.data.message);
    }
  }, [loginError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isAuth = useRecoilValue(isAuthenticated);

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  return (
    <Stack spacing={8} {...props}>
      <Stack spacing={6}>
        {isMobile && <LogoIcon />}
        <Stack spacing={{ base: 2, md: 3 }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Log in to your account
          </Heading>
          {/* <HStack spacing={1} justify="center">
            <Text color="gray.500">Don't have an account?</Text>
            <Button variant="link" colorScheme="blue">
              Sign up
            </Button>
          </HStack> */}
        </Stack>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6}>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="email">
                Email &nbsp;&nbsp;&nbsp;
                {formik.touched.email && formik.errors.email ? (
                  <span
                    style={{
                      color: "red",
                      textAlign: "start",
                      width: "fit-content",
                    }}
                  >
                    * {formik.errors.email}
                  </span>
                ) : null}
              </FormLabel>
              <Input
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                placeholder="Enter your email"
                type="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">
                Password &nbsp;&nbsp;&nbsp;
                {formik.touched.password && formik.errors.password ? (
                  <span
                    style={{
                      color: "red",
                      textAlign: "start",
                      width: "fit-content",
                    }}
                  >
                    * {formik.errors.password}
                  </span>
                ) : null}
              </FormLabel>{" "}
              <Input
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="password"
                placeholder="********"
                type="password"
              />
            </FormControl>
          </Stack>
          {/* <HStack justify="space-between">
            <Checkbox>Remember me</Checkbox>
            <Button variant="link" colorScheme="green" size="sm">
              Forgot password
            </Button>
          </HStack> */}
          <Stack spacing={4}>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // navigate("/register-new");
                formik.handleSubmit();
              }}
              colorScheme="green"
              variant="solid"
            >
              Sign in
            </Button>
            {/* <Button
            variant="outline"
            leftIcon={<GoogleIcon boxSize={5} />}
            iconSpacing={3}
          >
            Sign in with Google
          </Button> */}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
