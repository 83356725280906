import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/profile");

  }, []);

  return (
    <div
      style={{ width: "100%", height: "100vh" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <img
        src={require("./../../../Utils/img/success.png")}
        style={{ maxWidth: "196px" }}
        alt=""
      />
      <span className="text-center fw-bold pt-5">
        Your payment is successful. Redirecting...
      </span>
    </div>
  );
}

export default PaymentSuccess;
