import { useFormik } from "formik";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { usePost } from "./../../Services/API/APIHandlers";
import { ForgotPasswordDefault } from "./../../Services/Helper/AuthDefaults";
import { ForgotPasswordType } from "./../../Services/Types/AuthTypes";
import { ForgotPasswordValidation } from "./../../Services/Validations/AuthValidation";

function ForgotPassword() {
  const {
    mutate: forgotPassword,
    isLoading,
    isError,
    isSuccess,
  } = usePost("reset-password/");

  const formik = useFormik({
    initialValues: ForgotPasswordDefault,
    validationSchema: ForgotPasswordValidation,
    onSubmit: (values: ForgotPasswordType) => {
      let payload = {
        email: values.email,
      };

      // console.log(payload);
      forgotPassword(payload);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-4 p-5 rounded shadow">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-5 pb-5 fw-bold">Forgot Password</h1>
          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="email"
            >
              Email address
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.email && formik.errors.email ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.email}
              </span>
            ) : null}
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              type="email"
              id="email"
              className="form-control"
              placeholder="ex: abc@xyz.com"
            />
          </div>

          <div className="d-flex flex-row pt-5 justify-content-center">
            <button type="submit" className="btn btn-block btn-dark mb-4 px-5">
              <b>{!isLoading ? "SEND EMAIL" : "Loading..."}</b>
            </button>
          </div>
        </form>
      </div>
      <div className="text-center pt-2">
        <span className="fw-bold">
          Want to try again? <Link to="/login">Login</Link>
        </span>
      </div>
    </div>
  );
}

export default ForgotPassword;
