import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  CALLBACK_API,
  PAYMENT_API,
  STUDENT_PAYMENT_SUCCESS_API,
  SUCCESS_API,
  useGetValidate,
} from "../../../Services/API/APIHandlers";
import { FirstUserLoginInterface } from "../../../Services/Interfaces/CreateUserInterface";

type PropType = {
  amount: string;
};

function checkEmailInArray(email: string, emailArray: Array<string>) {
  for (let i = 0; i < emailArray.length; i++) {
    if (emailArray[i] === email) {
      return true;
    }
  }
  return false;
}

const emailArray = [
  "nikitamondal2005@gmail.com",
  // "nikhilshrivastava1025@gmail.com",
  "rakeshkumardhn3@gmail.com",
];

const Payment = () => {
  const navigate = useNavigate();
  function loadScript(src: any) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const [data, setData] = useState<FirstUserLoginInterface | null>(null);
  const {
    mutate: getProfile,
    isLoading,
    error: registerNewError,
    data: registerNewData,
    isSuccess,
  } = useGetValidate("/get-profile");

  useEffect(() => {
    if (isSuccess) {
      setData(registerNewData.data);
    }
  }, [isSuccess, registerNewData]);

  useEffect(() => {
    if (registerNewError instanceof AxiosError) {
      toast.error(registerNewError?.response?.data.message);
    }
  }, [registerNewError]);

  useEffect(() => {
    getProfile();
  }, []);

  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(localStorage.getItem("email") || "");
    // formik.setValues({ ...formik.values, email: email });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await axios.post(PAYMENT_API, {
      email: email,
      isEMI: data?.isEMI,
      emiNo: data?.emiNo,
    });

    if (!result) {
      return;
    }

    const { amount, id: orderId, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount,
      currency: currency,
      // name: "EdEarn Payments",
      description: "EdEarn Payments",
      order_id: orderId,
      callback_url: CALLBACK_API,
      redirect: false,
      handler: async function (response: any) {
        const dataToPayment = {
          email: email,
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        console.log(dataToPayment);

        // Confirm Order Placement
        const result = await axios.post(SUCCESS_API, dataToPayment);

        if (result.data) {
          // alert("Hui Hui");
          // Do Success Operations
          const subscribeToTraining = await axios.post(
            STUDENT_PAYMENT_SUCCESS_API,
            {
              email: email,
              paymentId: result.data.orderId,
              orderId: result.data.paymentId,
            }
          );

          if (subscribeToTraining) {
            console.log(subscribeToTraining);
          }

          toast.success("Successfully done");

          setTimeout(() => {
            navigate("/checkout/success");
          }, 3000);
        } else {
          alert("Payment Failed!!!");
          // navigate("/profile");
        }
      },
      // prefill: {
      //   // name: name,
      //   // email: formik.values.email,
      //   // contact: formik.values.phoneNumber,
      // },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  }

  return (
    <Box py={10} p={4}>
      <Flex
        mt={10}
        pt={10}
        justifyContent="space-between"
        alignItems="center"
        mb={5}
      >
        <Flex alignItems="center">
          <Heading size="sm" textTransform="uppercase" mt={1}>
            Qubit
          </Heading>
          <Box as="span" ms={2} me={3}>
            Pay
          </Box>
        </Flex>
        <a href="#!">Cancel and return to the website</a>
      </Flex>
      <Container>
        <Row>
          <Box flex="1" mr={4}>
            <Heading mb={0} color="green.500">
              &#8377;{" "}
              {data?.isEMI
                ? checkEmailInArray(email, emailArray)
                  ? "1500"
                  : "2500"
                : "4999"}
            </Heading>
            <Heading mb={3}>Full Stack Web Development</Heading>
            <Flex justify="space-between" mb={4}>
              <Flex flexDir="column">
                <Box fontWeight="bold">{data?.isEMI && "EMI"}</Box>
                <Box fontWeight="bold" color="green.500" ms={1}></Box>
              </Flex>
              <Box color="primary" textAlign="right" mt={1}></Box>
            </Flex>
            <Box mb={4}>
              <Flex bg="#eee" alignItems={"center"} justifyContent={"center"}>
                <MdVerified color="green" /> &nbsp;
                <span>Verified</span>
              </Flex>
            </Box>
            <Flex
              direction={"column"}
              p={2}
              justifyContent="space-between"
              alignItems="start"
              mb={4}
            >
              <Box textAlign={"start"}>
                <b>Address:</b>
              </Box>
              <Box>
                Sarala Birla University, Purulia Rd, near Namak Godam, Radhe
                Nagar, Jharkhand 835103
              </Box>
            </Flex>
            <Flex justify="space-between" mb={4}>
              <Flex flexDir="column">
                {/* <Box fontWeight="bold">Patient Balance</Box> */}
                <Box fontWeight="bold" color="green.500" ms={1}>
                  {/* $13.24 */}
                </Box>
              </Flex>
              <Box color="primary" textAlign="right" mt={1}>
                {/* Add Payment card */}
              </Box>
            </Flex>
            {/* <RadioGroup defaultValue="option2" mb={3}>
            <Radio value="option1">
            <Flex justify="space-between">
            <Box>VISA</Box>
            <Box>**** 5436</Box>
            </Flex>
            </Radio>
            <Radio value="option2">
            <Flex justify="space-between">
            <Box>MASTER CARD</Box>
            <Box>**** 5038</Box>
            </Flex>
            </Radio>
            </RadioGroup> */}
            <Button
              onClick={() => displayRazorpay()}
              colorScheme="green"
              size="lg"
              width="100%"
            >
              Proceed to payment
            </Button>
            <Box className="badge text-dark" mb={4}>
              <b>* EDEARN</b> Payment Services are <br /> secure and legit for
              transactions
            </Box>
          </Box>
          <Box
            p={3}
            bg="#eee"
            borderRadius="md"
            flex="1"
            ml={{ base: 0, md: 4 }}
            mt={{ base: 4, md: 0 }}
          >
            <Heading size="sm" fontWeight="bold" mb={2}>
              Order Recap
            </Heading>
            <Box mb={2}>
              <Flex justify="space-between">
                <Box>Tuition Fee</Box>
                <Box>&#8377; 2499.00</Box>
              </Flex>
              <Flex justify="space-between">
                <Box>Training Fee</Box>
                <Box>&#8377; 2000.00</Box>
              </Flex>
              <Flex justify="space-between">
                <Box>Miscellaneous</Box>
                <Box>&#8377; 500.00</Box>
              </Flex>
              {data?.isEMI && (
                <Flex fontWeight={"bold"} justify="space-between">
                  <Box>EMI Charge Split</Box>

                  <Box>
                    {" "}
                    - &#8377;{" "}
                    {checkEmailInArray(email, emailArray)
                      ? "3500.00"
                      : "2500.00"}
                  </Box>
                </Flex>
              )}
            </Box>

            <Box borderBottom="1px solid" my={2} />
            <Flex fontWeight={"bold"} justify="space-between">
              <Box>Total</Box>
              <Box color="green.500">
                &#8377;{" "}
                {data?.isEMI
                  ? checkEmailInArray(email, emailArray)
                    ? "1500.00"
                    : "2500.00"
                  : "4999"}
              </Box>
            </Flex>
          </Box>
        </Row>
      </Container>
    </Box>
  );
};

export default Payment;
