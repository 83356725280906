import { useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../Dashboard/Navbar";
import { Sidebar } from "../../Dashboard/Sidebar";
import { isTokenValid } from "../API/APIHandlers";

type AuthenticatedRouteProps = {
  children: React.ReactNode | string;
};

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const navigate = useNavigate();
  const [tokenValid, setIsTokenValid] = useState<boolean>(true);

  useEffect(() => {
    let isValid = isTokenValid();
    setIsTokenValid(true);
    if (!isValid) {
      setIsTokenValid(false);
      navigate("/");
    }

    return () => {};
  }, []);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <React.Fragment>
      {" "}
      <div className="d-flex bg-light">
        <div className={isDesktop ? "col-2" : "col-0"}>
          {isDesktop ? <Sidebar /> : <Navbar />}
        </div>
        <div className={isDesktop ? "col-10" : "col-12"}>
          {!tokenValid ? null : props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthenticatedRoute;
