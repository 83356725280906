import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiBookmark, FiLogOut, FiUsers } from "react-icons/fi";
// import { NavButton } from "./NavButton";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isAuthenticated } from "./../Services/Atoms/AuthAtoms";
import { NavButton } from "./NavButton";

export const Sidebar = () => {
  const navigate = useNavigate();

  const [isAuthenticatedValue, setIsAuthenticatedValue] =
    useRecoilState(isAuthenticated);
  return (
    <Flex as="section" minH="100vh" bgColor={"#fff"} bg="bg-surface">
      <Flex
        flex="1"
        bgColor={"white"}
        bg="bg-surface"
        // overflowY="auto"
        position={"fixed"}
        zIndex={100}
        bottom={0}
        top={0}
        left={0}
        boxShadow={useColorModeValue("sm", "sm-dark")}
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
            <HStack justify="center">
              <img
                src={require("./../Utils/img/logo_green.jpeg")}
                alt="Logo UI"
                width={164}
              />
            </HStack>
            <Divider />

            {/* <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" />
            </InputGroup> */}
            <Stack spacing="1">
              {/* <NavButton
                onClick={() => navigate("/dashboard")}
                label="Home"
                icon={FiHome}
              /> */}
              {/* <NavButton
              label="Dashboard"
              icon={FiBarChart2}
              aria-current="page"
            /> */}
              {/* <NavButton label="Tasks" icon={FiCheckSquare} /> */}
              <NavButton
                onClick={() => navigate("/payment-details")}
                label="My Courses"
                icon={FiBookmark}
              />
              <NavButton
                onClick={() => navigate("/profile")}
                label="Profile"
                icon={FiUsers}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            {/* <Stack spacing="1">
            <NavButton label="Help" icon={FiHelpCircle} />
            <NavButton label="Settings" icon={FiSettings} />
          </Stack> */}
            <Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
              <Stack spacing="4">
                {/* <Stack spacing="1">
                <Text fontSize="sm" fontWeight="medium">
                  Almost there
                </Text>
                <Text fontSize="sm" color="muted">
                  Fill in some more information about you and your person.
                </Text>
              </Stack> */}
                {/* <Progress
                value={80}
                size="sm"
                aria-label="Profile Update Progress"
              /> */}
                {/* <HStack spacing="3">
                <Button variant="link" size="sm">
                  Dismiss
                </Button>
                <Button variant="link" size="sm" colorScheme="blue">
                  Update profile
                </Button>
              </HStack> */}
              </Stack>
            </Box>
            <Divider />
            <NavButton
              onClick={() => {
                localStorage.clear();
                navigate("/");
                setIsAuthenticatedValue(false);
              }}
              label="Logout"
              icon={FiLogOut}
            />
            {/* <UserProfile
              name="Christoph Winston"
              image="https://tinyurl.com/yhkm2ek8"
              email="chris@chakra-ui.com"
            /> */}
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
