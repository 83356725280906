import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { usePost } from "./../../Services/API/APIHandlers";
import {
  isAuthenticated,
  localTokenSet,
} from "./../../Services/Atoms/AuthAtoms";
import { SignUpDefault } from "./../../Services/Helper/AuthDefaults";
import {
  LocalTokenSetType,
  SignUpType,
} from "./../../Services/Types/AuthTypes";
import { SignUpValidation } from "./../../Services/Validations/AuthValidation";

function SignUp() {
  const navigate = useNavigate();
  const [localTokenData, setLocalTokenData] = useRecoilState(localTokenSet);

  const {
    mutate: register,
    isLoading,
    data: registerData,
    error: registerError,
    isSuccess,
  } = usePost("api/register/");

  const formik = useFormik({
    initialValues: SignUpDefault,
    validationSchema: SignUpValidation,
    onSubmit: (values: SignUpType) => {
      let payload = {
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        password: values.password,
        password_confirmation: values.confirm_password,
      };
      // console.log(payload);
      register(payload);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      let tokenData: LocalTokenSetType = {
        accessToken: registerData.data.access_token,
        refreshToken: registerData.data.refresh_token,
      };
      // Call a toast
      // toast.success("Registration Successfull");
      setLocalTokenData(tokenData);
      setRecoil(isAuthenticated, true);
      localStorage.setItem("token", JSON.stringify(tokenData));
      // navigate(-1);
    }
  }, [isSuccess, registerData, setLocalTokenData]);

  useEffect(() => {
    if (registerError instanceof AxiosError) {
      // Call a toast
      // toast.error(
      //   registerError?.response?.data.phone_number[0] ||
      //     registerError?.response?.data.email[0]
      // );
    }
  }, [registerError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isAuth = useRecoilValue(isAuthenticated);

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div
        className="col-md-4 p-5 rounded shadow"
      >
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-4 fw-bold">Hello!</h1>
          <p className="pb-5">Create An Account</p>
          <div className="form-outline mb-3">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="name"
            >
              Name
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.name && formik.errors.name ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.name}
              </span>
            ) : null}
            <input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              type="text"
              id="name"
              className="form-control"
              placeholder="Sushruta"
            />
          </div>

          <div className="form-outline mb-3">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="email"
            >
              Email
            </label>
            &nbsp;&nbsp;&nbsp;
            {formik.touched.email && formik.errors.email ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.email}
              </span>
            ) : null}
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              type="email"
              id="email"
              className="form-control"
              placeholder="E.g. abc@xyz.com"
            />
          </div>

          <div className="form-outline mb-3">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="phone_number"
            >
              Phone Number
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.phone_number}
              </span>
            ) : null}
            <input
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone_number"
              type="tel"
              id="phone_number"
              className="form-control"
              placeholder="ex: 98765XXXXX"
            />
          </div>

          <div className="form-outline mb-3">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="password"
            >
              Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.password && formik.errors.password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.password}
              </span>
            ) : null}
            <input
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              type="password"
              id="password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="form-outline mb-3">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.confirm_password}
              </span>
            ) : null}
            <input
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="confirm_password"
              type="password"
              id="confirm_password"
              className="form-control"
              placeholder="8 char minimum"
            />
          </div>

          <div className="d-flex flex-row justify-content-center">
            <button
              type="submit"
              className="btn btn-block btn-dark mb-4 px-5 rounded-3"
            >
              <b>{!isLoading ? "SIGN UP" : "Loading..."}</b>
            </button>
          </div>

          {/* <div className="text-center">
            <p>or sign up with:</p>
            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-facebook-f"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-google"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-twitter"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-github"></i>
            </button>
          </div> */}
        </form>
      </div>

      <div className="text-center pt-2">
        <span className="fw-bold">
          Already a member? <Link to="/login">Login</Link>
        </span>
      </div>
    </div>
  );
}

export default SignUp;
