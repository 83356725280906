import {
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  useColorModeValue as mode,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SignInForm } from "./SignInForm";

export const MainLogin = () => {

  
  return (
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={useBreakpointValue({
        md: mode(
          "linear(to-r, green 50%, white 50%)",
          "linear(to-r, green 50%, gray.900 50%)"
        ),
      })}
      // bg={"green"}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex
              direction="column"
              px={{ base: 4, md: 8 }}
              height="full"
              color={"white"}
            >
              <Flex align="center" pt={{ base: 10, md: 10 }}>
                {/* <Logo /> */}
                <img src={require("./../../Utils/img/logo.png")} alt="Logo UI" width={164} />
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing={8}>
                  <Stack spacing={6}>
                    <Heading size={useBreakpointValue({ md: "lg", xl: "xl" })}>
                      Start making your dreams come true
                    </Heading>
                    <Text fontSize="lg" maxW="md" fontWeight="medium">
                      Create an account and discover the worlds' best UI
                      component framework.
                    </Text>
                  </Stack>
                  {/* <HStack spacing={4}>
                  <AvatarGroup
                    size="md"
                    max={useBreakpointValue({ base: 2, lg: 5 })}
                    borderColor="on-accent"
                  >
                    <Avatar
                      name="Ryan Florence"
                      src="https://bit.ly/ryan-florence"
                    />
                    <Avatar
                      name="Segun Adebayo"
                      src="https://bit.ly/sage-adebayo"
                    />
                    <Avatar
                      name="Kent Dodds"
                      src="https://bit.ly/kent-c-dodds"
                    />
                    <Avatar
                      name="Prosper Otemuyiwa"
                      src="https://bit.ly/prosper-baba"
                    />
                    <Avatar
                      name="Christian Nwamba"
                      src="https://bit.ly/code-beast"
                    />
                  </AvatarGroup>
                  <Text fontWeight="medium">Join 10,000+ users</Text>
                </HStack> */}
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="on-accent-subtle" fontSize="sm">
                  © 2022 Chakra UI. All rights reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <SignInForm
            px={{ base: 4, md: 8 }}
            py={{ base: 12, md: 25 }}
            width="full"
            maxW="md"
          />
        </Center>
      </Flex>
    </Flex>
  );
};
