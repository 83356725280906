import React from "react";

const AutoDownload: React.FC = () => {
  const filename =
    "4pyhgWQU_wfN_kmuH8Y1-ylRuBsLWREVS5BHH7GSakeYwnGxYWojq6ukBEKlQ563";
  const content = "Hello, world!";

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };

  React.useEffect(() => {
    downloadTxtFile();
    // Cleanup
    return () => {
      URL.revokeObjectURL(filename);
    };
  }, []);

  return null;
};

export default AutoDownload;
