import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import ChangePassword from "../Auth/ChangePassword";
import ForgotPassword from "../Auth/ForgotPassword";
import { MainLogin } from "../Auth/Login/MainLogin";
import OTPVerification from "../Auth/OTPVerification";
import PhoneVerification from "../Auth/PhoneVerification";
import ResetPassword from "../Auth/ResetPassword";
import SignUp from "../Auth/SignUp";
import { Dashboard } from "../Dashboard";
import PaymentDetails from "../Dashboard/Pages/PaymentDetails";
import Payment from "../Dashboard/Pages/PaymentPage";
import Profile from "../Dashboard/Pages/Profile";
import { FirstRegistration } from "../Dashboard/Pages/RegistrationOnFirstLogin";
import NotFound from "../NotFound";
import AuthenticatedRoute from "../Services/Routes/AuthenticatedRoute";
import PlainRoute from "../Services/Routes/PlainRoute";
import AutoDownload from "./AutoDownload";
import PaymentSuccess from "../Dashboard/Pages/PaymentSuccess";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <RecoilNexus />
        <Router>
          {/* <Navbar /> */}
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="/register-new" element={<FirstRegistration />} />
            <Route path="/checkout/success" element={<PaymentSuccess />} />
            <Route
              path="/.well-known/acme-challenge/4pyhgWQU_wfN_kmuH8Y1-ylRuBsLWREVS5BHH7GSakeYwnGxYWojq6ukBEKlQ563"
              element={<AutoDownload />}
            />
            {/* <Route path="/" element={<Payment />} /> */}

            <Route
              path="/payment"
              element={
                <AuthenticatedRoute>
                  <Payment />
                </AuthenticatedRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <AuthenticatedRoute>
                  <Dashboard />
                </AuthenticatedRoute>
              }
            />

            <Route
              path="/payment-details"
              element={
                <AuthenticatedRoute>
                  <PaymentDetails />
                </AuthenticatedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <AuthenticatedRoute>
                  <Profile />
                </AuthenticatedRoute>
              }
            />

            <Route
              path="/otp-verify"
              element={
                <PlainRoute>
                  <OTPVerification />
                </PlainRoute>
              }
            />
            {/* <Route
              path="/login"
              element={
                <PlainRoute>
                  <Login />
                </PlainRoute>
              }
            /> */}

            <Route
              path="/"
              element={
                <PlainRoute>
                  <MainLogin />
                </PlainRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PlainRoute>
                  <SignUp />
                </PlainRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <PlainRoute>
                  <ResetPassword />
                </PlainRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <PlainRoute>
                  <ForgotPassword />
                </PlainRoute>
              }
            />
            <Route
              path="/phone-verify"
              element={
                <PlainRoute>
                  <PhoneVerification />
                </PlainRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <PlainRoute>
                  <ChangePassword />
                </PlainRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
