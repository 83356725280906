import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { usePost } from "./../../Services/API/APIHandlers";
import { OTPFormDefault } from "./../../Services/Helper/AuthDefaults";
import { OTPFormType } from "./../../Services/Types/AuthTypes";
import { OTPFormValidation } from "./../../Services/Validations/AuthValidation";

function OTPVerification() {
  const {
    mutate: verifyOTP,
    isLoading,
    isError,
    isSuccess,
  } = usePost("/otp-verify");

  const [otpValue, setOtpValue] = useState<string>("");

  const formik = useFormik({
    initialValues: OTPFormDefault,
    validationSchema: OTPFormValidation,
    onSubmit: (values: OTPFormType) => {
      const data: OTPFormType = JSON.parse(JSON.stringify(values));
      console.log(values);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container auth-blocks py-4 d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-4 p-5 rounded shadow">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="display-4 fw-bold">One Time Password</h1>
          <div className="form-outline mb-4">
            <label
              className="form-label auth-form-label text-start"
              htmlFor="otp"
            >
              Enter OTP
            </label>
            &nbsp;&nbsp;&nbsp;{" "}
            {formik.touched.otp && formik.errors.otp ? (
              <span
                style={{
                  color: "red",
                  textAlign: "start",
                  width: "fit-content",
                }}
              >
                * {formik.errors.otp}
              </span>
            ) : null}
            <input
              style={{
                letterSpacing: "20px",
              }}
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              id="otp"
              name="otp"
              maxLength={6}
              className="form-control text-center"
              placeholder="XXXXXX"
            />
          </div>

          <div className="d-flex pt-3 flex-row justify-content-center">
            <button
              type="submit"
              className="btn btn-block btn-dark mb-4 px-5 rounded-3"
            >
              <b>{!isLoading ? "CONFIRM" : "Loading..."}</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OTPVerification;
