import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { usePost } from "../../../Services/API/APIHandlers";
import { isAuthenticated } from "../../../Services/Atoms/AuthAtoms";
import { FirstUserLoginInterface } from "../../../Services/Interfaces/CreateUserInterface";
import FirstLoginValidation from "../../../Services/Validations/CreateUserValidations";

export const FirstRegistration: React.FC = () => {
  const [currentEmail, setCurrentEmail] = useState<string>("");

  const formik = useFormik<FirstUserLoginInterface>({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      course: "Full Stack Web Development",
      institutionType: "",
      institutionName: "",
      degreeName: "",
      semester: "",
      graduationYear: "",
      isEMI: false,
      status: false,
      emiNo: 1,
      class: "",
      passingYear: "",
      codingExperience: "",
      trainingType: "",
      languagesKnown: "",
      batch: "June 2024",
      terms: false,
    },
    validationSchema: FirstLoginValidation,
    onSubmit: (values) => {
      registerNew({ ...values, otherEmail: currentEmail });
      localStorage.setItem("email", currentEmail);
    },
  });

  const {
    mutate: registerNew,
    isLoading,
    error: registerNewError,
    data: registerNewData,
    isSuccess,
  } = usePost("/register-new");

  useEffect(() => {
    if (isSuccess) {
      // Call a toast

      localStorage.setItem("v", JSON.stringify({ verified: true }));
      toast.success("Registered Successfully. Login Again");
      // toast.success("Thank you for the details");
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 3000);
    }
  }, [isSuccess, registerNewData]);

  useEffect(() => {
    if (registerNewError instanceof AxiosError) {
      // Call a toast
      toast.error(registerNewError?.response?.data.message);
    }
  }, [registerNewError]);
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email") || "";
    const isVerified = JSON.parse(localStorage.getItem("v") || "{}");
    if (isVerified.verified) {
      navigate("/profile");
    }
    formik.setValues({ ...formik.values, email: email });
    setCurrentEmail(email);
    window.scrollTo(0, 0);
  }, []);

  const [institutionType, setInstitutionType] = useState("");

  const isAuth = useRecoilValue(isAuthenticated);

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  return (
    <Box width={["100%", "90%", "80%", "70%"]} mx="auto" mt={8} p={4}>
      <Heading as="h1" size="lg" mb={10}>
        Complete your profile
      </Heading>

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
            <FormControl
              isInvalid={!!formik.errors.name && formik.touched.name}
            >
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input id="name" {...formik.getFieldProps("name")} />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!formik.errors.email && formik.touched.email}
            >
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                {...formik.getFieldProps("email")}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!formik.errors.phone && formik.touched.phone}
            >
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <Input id="phone" {...formik.getFieldProps("phone")} />
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!formik.errors.course && formik.touched.course}
            >
              <FormLabel htmlFor="course">Program Name</FormLabel>
              <Input
                isDisabled={true}
                id="course"
                {...formik.getFieldProps("course")}
              />
              <FormErrorMessage>{formik.errors.course}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={
                !!formik.errors.institutionType &&
                formik.touched.institutionType
              }
            >
              <FormLabel htmlFor="institutionType">Institution Type</FormLabel>
              <Select
                id="institutionType"
                {...formik.getFieldProps("institutionType")}
                onChange={(e) => {
                  formik.handleChange(e);
                  setInstitutionType(e.target.value);
                }}
              >
                <option value="">Select Institution Type</option>
                <option value="College/University">College/University</option>
                <option value="School">School</option>
              </Select>
              <FormErrorMessage>
                {formik.errors.institutionType}
              </FormErrorMessage>
            </FormControl>
          </Grid>

          <FormControl
            isInvalid={
              !!formik.errors.institutionName && formik.touched.institutionName
            }
          >
            <FormLabel htmlFor="institutionName">Institution Name</FormLabel>
            <Input
              id="institutionName"
              {...formik.getFieldProps("institutionName")}
            />
            <FormErrorMessage>{formik.errors.institutionName}</FormErrorMessage>
          </FormControl>

          {institutionType === "College/University" && (
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
              gap={4}
            >
              <FormControl
                isInvalid={
                  !!formik.errors.degreeName && formik.touched.degreeName
                }
              >
                <FormLabel htmlFor="degreeName">Bachelor Degree Name</FormLabel>
                <Select id="degreeName" {...formik.getFieldProps("degreeName")}>
                  <option value="">Select Degree</option>
                  <option value="BCA">BCA</option>
                  <option value="BTech">BTech</option>
                  <option value="Others">Others</option>
                </Select>
                <FormErrorMessage>{formik.errors.degreeName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.semester && formik.touched.semester}
              >
                <FormLabel htmlFor="semester">Semester</FormLabel>
                <Select id="semester" {...formik.getFieldProps("semester")}>
                  <option value="">Select Semester</option>
                  {["I", "II", "III", "IV", "V", "VI", "VII", "VIII"].map(
                    (sem) => (
                      <option key={sem} value={sem}>
                        {sem}
                      </option>
                    )
                  )}
                </Select>
                <FormErrorMessage>{formik.errors.semester}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!formik.errors.graduationYear &&
                  formik.touched.graduationYear
                }
              >
                <FormLabel htmlFor="graduationYear">
                  Year of Graduation
                </FormLabel>
                <Input
                  id="graduationYear"
                  {...formik.getFieldProps("graduationYear")}
                />
                <FormErrorMessage>
                  {formik.errors.graduationYear}
                </FormErrorMessage>
              </FormControl>
            </Grid>
          )}

          {institutionType === "School" && (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <FormControl
                isInvalid={!!formik.errors.class && formik.touched.class}
              >
                <FormLabel htmlFor="class">Class</FormLabel>
                <Select id="class" {...formik.getFieldProps("class")}>
                  <option value="">Select Class</option>
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                  <option value="12th Passout">12th Passout</option>
                </Select>
                <FormErrorMessage>{formik.errors.class}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!formik.errors.passingYear && formik.touched.passingYear
                }
              >
                <FormLabel htmlFor="passingYear">Year of Passing</FormLabel>
                <Input
                  id="passingYear"
                  {...formik.getFieldProps("passingYear")}
                />
                <FormErrorMessage>{formik.errors.passingYear}</FormErrorMessage>
              </FormControl>
            </Grid>
          )}

          <FormControl
            isInvalid={
              !!formik.errors.codingExperience &&
              formik.touched.codingExperience
            }
          >
            <FormLabel htmlFor="codingExperience">Coding Experience</FormLabel>
            <Select
              id="codingExperience"
              {...formik.getFieldProps("codingExperience")}
            >
              <option value="">Select Experience Level</option>
              <option value="Beginner">Beginner</option>
              <option value="Medium">Medium</option>
              <option value="Professional">Professional</option>
            </Select>
            <FormErrorMessage>
              {formik.errors.codingExperience}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!formik.errors.trainingType && formik.touched.trainingType
            }
          >
            <FormLabel htmlFor="trainingType">Training Type</FormLabel>
            <Select id="trainingType" {...formik.getFieldProps("trainingType")}>
              <option value="">Select Training Type</option>
              <option value="Beginner">Beginner</option>
              <option value="Premium">Premium</option>
              <option value="Advanced">Advanced</option>
            </Select>
            <FormErrorMessage>{formik.errors.trainingType}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="languagesKnown">Languages Known</FormLabel>
            <Textarea
              id="languagesKnown"
              {...formik.getFieldProps("languagesKnown")}
            />
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.batch && formik.touched.batch}
          >
            <FormLabel htmlFor="batch">Batch</FormLabel>
            <Select id="batch" {...formik.getFieldProps("batch")}>
              <option value="June 2024">June 2024</option>
            </Select>
            <FormErrorMessage>{formik.errors.batch}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.terms && formik.touched.terms}
          >
            <Checkbox id="terms" {...formik.getFieldProps("terms")}>
              I agree to the{" "}
              <Link color="teal.500" href="/terms-and-conditions" isExternal>
                terms and conditions
              </Link>
            </Checkbox>
            <FormErrorMessage>{formik.errors.terms}</FormErrorMessage>
          </FormControl>

          <Button
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              // navigate("/profile");
            }}
            type="submit"
            colorScheme="green"
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
