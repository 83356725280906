import { ChangePasswordType, ForgotPasswordType, LoginType, OTPFormType, PhoneVerificationType, ResetPasswordType, SignUpType } from "../Types/AuthTypes";

export const SignUpDefault: SignUpType = {
    name: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: ''
};

export const LoginDefault: LoginType = {
    email: '',
    password: '',
};

export const ForgotPasswordDefault: ForgotPasswordType = {
    email: '',
};

export const ChangePasswordDefault: ChangePasswordType = {
    new_password: '',
    confirm_password: '',
    old_password: ''
};

export const ResetPasswordDefault: ResetPasswordType = {
    password: '',
    confirm_password: ''
};

export const PhoneVerificationDefault: PhoneVerificationType = {
    phone_number: ''
};

export const OTPFormDefault: OTPFormType = {
    otp: ''
};
